import React from 'react'
import styled from "styled-components"
import { Form, Button, Container } from "react-bootstrap"
import PageHeader from "../elements/pageHeader"

const Reservation = () => {
  return (
    <>
    <PageHeader header="Reservations" subHeader="Inquire about reservations and vacancies"/>
    <Contact>
      <p>To inquire please e-mail, phone or fill out the form below.</p>
      <ul>
        <li>Phone: <a href="tel:250-725-3789">250-725-3789</a></li>
        <li>E-mail: <a href="mailto:info@goldcoasttofino.com">info@goldcoasttofino.com</a></li>
      </ul>
    </Contact>  
    <FormContainer>
    <Container>  
      <Form
        name="reservationV3"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success"
        onSubmit="submit"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="reservationV3" />
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control required type="text" placeholder="Name" name="name"/>
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control required type="email" placeholder="Email Address" name="email-address"/>
          <Form.Text>Please enter a valid email address as this will be our primary method of contact.</Form.Text>
        </Form.Group>

        <Form.Group controlId="formPhone">
          <Form.Label>Phone</Form.Label>
          <Form.Control required type="text" placeholder="Daytime Phone" name="phone-number"/>
        </Form.Group>

        <Form.Group controlId="formArrivalDate">
          <Form.Label>Arrival Date</Form.Label>
          <Form.Control required type="date" name="arrival" placeholder="arrival date" name="arrival-date" />
        </Form.Group>

        <Form.Group controlId="formDepartureDate">
          <Form.Label>Departure Date</Form.Label>
          <Form.Control required type="date" name="departure" placeholder="departure date" name="departure-date" />
        </Form.Group>

        <fieldset>
          <Form.Group>
            <Form.Label>Choose your suite</Form.Label>
              <Form.Control required as="select" custom name="suite-choice">
            <option>The Shipwreck Studio Cabin</option>
            <option>The Haidaway Cottage (2 Bedroom)</option>
          </Form.Control>
          </Form.Group>
        </fieldset>

        <Form.Group controlId="formGuests">
          <Form.Label>Number of Guests</Form.Label>
          <Form.Control required as="select" custom name="number-of-guests">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formPets">
          <Form.Label>Are you bringing a pet?</Form.Label>
          <Form.Control required as="select" custom name="pets">
            <option>Yes</option>
            <option>No</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formAdditionalNotes">
          <Form.Label>Additional Notes</Form.Label>
          <Form.Control 
            as="textArea"
            rows="5"
            placeholder="Addional information you would like to include"          
            name="additional-notes"
          />
        </Form.Group>

        <Button 
          type="submit" 
          style={{
              background:"#093656", 
              borderRadius: "35px",
              padding: "12px 42px",
              border: "none",
              fontFamily: "Nunito, sans-serif",
              cursor: "pointer",
            }}
          >
            Submit Reservation Enquiry
          </Button>
      </Form>
    </Container> 
    </FormContainer>
  </>
  )
}

export default Reservation

 const FormContainer = styled.div`
  width: 50%;
  align-items: center;
  margin: auto;
  margin-bottom:3rem;

  @media screen and (max-width: 868px){
    width: initial;
  }
 `

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem calc((100vw - 1300px) / 2);
  margin-bottom: 3rem;
  align-items:center;
  padding-bottom: 0;
  padding-top: 2rem;

  p{
    font-size: 1.2rem;
  }

  ul {
    margin:auto;
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: #1673b5;
  }

  @media screen and (max-width: 495px){
    margin: 15px;
    padding: 3 5px;
  }
`
